import { combineReducers } from 'redux';
import reducer from './reducer';

export default combineReducers({
    reducer
});

// let selectionspans=document.querySelectorAll(`[data-vidid="${selectionVideoID}"]`)
// let filterselectionspans=[]
// // console.log("spans with video id",selectionspans)
// // console.log("selectionspans",selectionspans[0])
// for(let i=0;i<selectionspans.length;i++){
//   if(Number(selectionspans[i].getAttribute('data-from'))>=Number(startTime) && Number(selectionspans[i].getAttribute('data-to')<=Number(endTime)))
//   filterselectionspans.push(selectionspans[i])
// }
// console.log("filterspans",filterselectionspans)