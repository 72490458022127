import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/action";
import Icon from "@material-ui/core/Icon";
import axios from "axios";
import SideNav from "../sideNav/sideNav";
import "./highlights.css";
import TopBar from "../TopBar/topBar";
// import { browserHistory } from 'react-router'
import { useHistory } from "react-router-dom";
import { fireEvent } from "@testing-library/react";

const Highlights = (props) => {
  const [dataArray, setDataArrays] = useState([]);

  const [keyWords, setKeyWords] = useState({});

  useEffect(() => {
    // console.log("DID MOUNT HIGHLIGHT", props);
    if (props.location.state && props.location.state.data) {
      // console.log("YES COMing from allvideos with data",props)

      setDataArrays(props.location.state.data);
      setKeyWords(props.location.state.queryWords);
    } else if (props.location.state && props.location.state.fromIcon) {
      // console.log("COMING FROM ICON",props)
      setDataArrays(props.dataArray);
    } else if (props.dataArray.length > 0) {
      setDataArrays(props.dataArray);
      setKeyWords(props.queryWords);
    }
  }, []);

  useEffect(() => {
    props.setDataArray(dataArray);
  }, [dataArray]);

  const highlightWords = () => {
    if (!document.getElementsByClassName("marked").length > 0) {
      // console.log("DATA ARRAYYYYYYYYYYYYYYY",dataArray)
      const paras = document.getElementsByClassName("transQ");

      // console.log("PARAs",paras)
      let wordsArray = [];
      wordsArray.push(keyWords.keyword);
      wordsArray = [...wordsArray, ...keyWords.similars];
      // console.log("WORDS ARRAY", wordsArray)

      //  paras[1].innerHTML=paras[1].innerHTML.replace(regex,`<strong>${wordsArray[0]}</strong>`)
      for (let i = 0; i < paras.length; i++) {
        for (let j = 0; j < wordsArray.length; j++) {
          let regex = new RegExp(`${wordsArray[j]}`, "gi");
          paras[i].innerHTML = paras[i].innerHTML.replace(
            regex,
            (match) => `<mark class="marked">${match}</mark>`
          );
        }
      }
    }
  };

  return (
    <div className="highlightstopbar">
      <TopBar title={"Deep Aspect"}></TopBar>
      <div className="dashboardParent  col-md-12 " style={{ paddingRight: 0 }}>
        <div
          className="sidenav col-sm-2 "
          style={{
            paddingRight: 0,
          }}
        >
          <SideNav></SideNav>
        </div>
      </div>
      <div style={{ textAlign: "center", margin: "15px 0px 25px 0px" }}>
        <h3>Search Results</h3>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          {keyWords.keyword && (
            <div>
              <span style={{ marginRight: 5, fontSize: 16 }}>
                <strong>Searched Keyword:</strong>
              </span>
              <span
                style={{
                  fonrtSize: 14,
                  backgroundColor: "rgba(252, 148, 3,0.7)",
                  padding: "8px 10px",
                  borderRadius: 5,
                }}
              >
                {keyWords.keyword}
              </span>
            </div>
          )}

          <div
            style={{
              margin: "15px 0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {dataArray.length > 0 && (
              <span style={{ alignSelf: "center" }}>
                <strong>Suggested Words by m.l. model:</strong>
              </span>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                marginTop: 10,
              }}
            >
              {keyWords.similars &&
                keyWords.similars.length > 0 &&
                keyWords.similars.map((data) => {
                  return (
                    <span
                      style={{
                        borderRadius: 5,
                        fontSize: 12,
                        marginRight: 12,
                        marginLeft: 12,
                        marginTop: 5,
                        backgroundColor: "rgba(252, 148, 3,0.7)",
                        padding: "3px 5px",
                      }}
                    >
                      {data}
                    </span>
                  );
                })}
            </div>
          </div>

          {/* <div>{warning && <h5 style={{color:"rgba(255,99,71,1)" ,fontSize:"12px",marginTop:5}}>{warningMsg}</h5>}</div> */}
        </div>
        {dataArray &&
          dataArray.map((data) => {
            {
              /* console.log("innnnnnnnnnnnnnnnnnnnnnnnnnn") */
            }
            return (
              <div className="transResults">
                <h3 style={{ fontSize: "1.5rem" }}>
                  <u>{data[0].videoName}</u>
                </h3>
                {data &&
                  data.map((trans) => {
                    {
                      /* console.log("transsss", trans); */
                    }
                    return (
                      <div className="eachTrans">
                        {trans.transType.includes("Q") ? (
                          <div>
                            <img
                              className="userImg"
                              src="https://cdn.pixabay.com/photo/2017/07/18/23/23/user-2517433_960_720.png"
                            />
                            <b style={{ marginBottom: " 0 !important" }}>
                              {trans.speakerNameOne}
                            </b>
                          </div>
                        ) : (
                          <div>
                            <img
                              className="userImg"
                              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                            />
                            <b style={{ marginBottom: "0px !important" }}>
                              {trans.speakerNameTwo}
                            </b>
                          </div>
                        )}
                        <Link
                          style={{ margin: 0 }}
                          to={{
                            pathname: "/",
                            highProps: {
                              videoName: trans.videoName,
                              transType: trans.transType,
                              transId: trans.transcriptId,
                            },
                          }}
                        >
                          {/* {console.log("TRANS",trans)} */}
                          <p
                            className="transQ"
                            id={trans.transcriptId}
                            onClick={() => {
                              // console.log("eeeeeeeeeeeee", trans.transcriptId);
                              props.setSelectedTranscript(trans.transcriptId);
                            }}
                          >
                            {trans.wordsArray.map((word, wordIndex) => {
                              return <span>{word.word} </span>;
                            })}
                          </p>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            );
          })}
        {dataArray.length > 0 && highlightWords()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataArray: state.reducer.dataArray,

    queryWords: state.reducer.queryWords,
    selectedTranscript: state.reducer.selectedTranscript,
  };
};

export default connect(mapStateToProps, actionTypes)(Highlights);
