import React, { useEffect, useState } from "react";
// import App from "../App/App";
import Home from "../Home/Home";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/action";
import { useHistory } from "react-router-dom";
import axios from "axios";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";

// import Detail from "../Table/table";
import Icon from "@material-ui/core/Icon";

import "./sideNav.css";

import { Route, BrowserRouter as Router, Switch, Link } from "react-router-dom";
import AllVideos from "../Allvideos/AllVideos";
import { Details } from "@material-ui/icons";
import { propTypes } from "react-bootstrap/esm/Image";
const SideNav = (props) => {
  const history = useHistory();
  const [details, setDetails] = useState([]);
  useEffect(() => {
    // fetchdetails();

    props.getAllVideos();
    props.setSideNav();
    setDetails(props.allVideos);
    // console.log("in useEffect mount");
    let id = window.location.pathname;
    // console.log("iiiidddd", id);
    id = id.substr(0, 10);
    // "iiiidddd", id);
    if (id === "/") setDropdown(true);
  }, []);

  useEffect(() => {
    setDetails(props.allVideos);
    // console.log("data from reduuuuuxxxxxx", props.allVideos);
  }, [props.allVideos]);
  useEffect(() => {
    // console.log('AAAAAAAA')
    // console.log("data from disable reduuuuuxxxxxx", props.show);
    // console.log("data from disable reduuuuuxxxxxx", props.disable);
  }, [props.disable, props.show]);

  // const fetchdetails = async () => {
  //   axios
  //     .get("https://videotranscribebackend-ndfvcc6pvq-uc.a.run.app/allVideos")
  //     .then(async (data) => {
  //       console.log("data", data.data.allVideos);
  //       await setDetails(data.data.allVideos);
  //       console.log("data here", details);
  //     });
  // };
  const [dropdown, setDropdown] = useState(false);
  return (
    <div>
      <nav id="sidebar">
        <div className="sidebar-header">
          {/* <div className="companyLogo">
            <img src="" alt="CogniAIm"></img>
          </div> */}
        </div>

        <ul className="list-unstyled components">
          <li>
            <Link
              to="/upload"
              onClick={(e) => {
                setDropdown(false);
                if (props && props.disable) {
                  e.preventDefault();
                }
              }}
            >
              <h6 style={{ fontWeight: 400, display: "flex" }}>
                <Icon style={{ marginRight: 6 }}>backup</Icon>
              </h6>
            </Link>
          </li>
          <li style={{ paddingTop: 5 }}>
            <Link
              className="main"
              to="/"
              onClick={(e) => {
                if (props && props.disable) {
                  e.preventDefault();
                }
              }}
            >
              <h6 style={{ fontWeight: 400, display: "flex" }}>
                <Icon style={{ marginRight: 6 }}>apps_icon </Icon>
              </h6>
              {props && props.disable ? (
                <div className="popup">
                  <span>Wait video is uploading</span>
                </div>
              ) : null}
            </Link>
          </li>
          <li style={{ paddingTop: 5 }}>
            <Link
              className="main"
              to="/Highlights"
              onClick={(e) => {
                if (props && props.disable) {
                  e.preventDefault();
                }
              }}
            >
              <h6 style={{ fontWeight: 400, display: "flex" }}>
                <Icon style={{ marginRight: 6 }}>search_icon</Icon>
              </h6>
              {props && props.disable ? (
                <div className="popup">
                  <span>Wait video is uploading</span>
                </div>
              ) : null}
            </Link>
          </li>
          <li style={{ paddingTop: 5 }}>
            <Link
              className="main"
              to="/summary"
              onClick={(e) => {
                if (props && props.disable) {
                  e.preventDefault();
                }
              }}
            >
              <h6 style={{ fontWeight: 400, display: "flex" }}>
                <Icon style={{ marginRight: 6 }}>folder_icon </Icon>
              </h6>
              {props && props.disable ? (
                <div className="popup">
                  <span>Wait video is uploading</span>
                </div>
              ) : null}
            </Link>
          </li>
          <li style={{ paddingTop: 5 }}>
            <Link
              className="main"
              to="/TrimmedVideos"
              onClick={(e) => {
                if (props && props.disable) {
                  e.preventDefault();
                }
              }}
            >
              <h6 style={{ fontWeight: 400, display: "flex" }}>
                <AddToPhotosIcon style={{ marginRight: 6 }}> </AddToPhotosIcon>
              </h6>
              {props && props.disable ? (
                <div className="popup">
                  <span>Wait video is uploading</span>
                </div>
              ) : null}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allVideos: state.reducer.allVideos,
    disable: state.reducer.disable,
    show: state.reducer.show,
  };
};

export default connect(mapStateToProps, actionTypes)(SideNav);
