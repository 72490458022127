import React, { lazy, Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  HashRouter,
  Switch,
} from "react-router-dom";
import Summary from "../Dashboard/Summary/summary";
import Highlights from "../Dashboard/Allvideos/Highlights";
import popup from "../Dashboard/Popup/popup";
import TrimmedVideos from "../Dashboard/TrimmedVideos/TrimmedVideos";

const AllVideos = lazy(() => import("../Dashboard/Allvideos/AllVideos"));

const Home = lazy(() => import("../Dashboard/Home/Home"));

const Routing = (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/upload" exact component={Home} />
        <Route path="/summary" exact component={Summary} />
        <Route path="/" exact component={AllVideos} />
        <Route path="/Highlights" exact component={Highlights} />
        <Route path="/popup.js" exact component={popup} />
        <Route path="/TrimmedVideos" exact component={TrimmedVideos} />
      </Switch>
    </Suspense>
  </Router>
);

export default Routing;
