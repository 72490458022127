import React from "react";
import "./popup.css"
import * as actionTypes from "../../Store/Action/action";
import { connect } from "react-redux";
 
const Popup = (props) => {
  
  const handleGroup= async(group)=>{
    // console.log("hannndddle grouppp",group)
    let id=[]
    for(let i=0;i<props.groupChecked.length;i++)
    {
id.push(props.groupChecked[i]._id)
    }
    // console.log("idddssss",id)
  let gone= await props.setGroup(group,id)
  //  console.log("gonee",gone)
   props.getAllVideos()
   props.handleClose(false)
  }
  // console.log("popppppuppppppp",props)
  return (
    <div className="popup-box">
      <div className="box">
        
        <h5 style={{textAlign:"center"}}>Move to </h5>

        <span className="close-icon" onClick={()=>{props.handleClose(false)}}>x</span>
        {props && props.groupChecked? 
        <div>
        
            {props && props.content && props.content.map((data,i)=>{
                 {/* console.log("grpppppppppp",data) */}
                return (
                    <p className="groupp" onClick={()=>{handleGroup(data)}}>{i+1}. Group {`${data}`}  </p>
                )
            })}
           </div>:null}     </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
   
    groupChecked:state.reducer.groupChecked
  };
};
export default connect(mapStateToProps, actionTypes)(Popup);