const axios = require("axios");

export const subscribeGet = async (
  subData,
  counterr,
  startData,
  //   uuid,
  filename
) => {
  // console.log("in subscribe", subData, counterr, startData, filename);
  let axiosData = await axios({
    method: "get",
    url: `https://api.eu-gb.speech-to-text.watson.cloud.ibm.com/instances/2c5bc0c0-177f-490a-9794-1dfb29e13340/v1/recognitions/${subData.id}`,
    auth: {
      username: "apikey",
      password: "CEk1_IZH-i8bSNBLXE1ywofzY6J6ybBN310gTQ2V4K5X",
    },
  });

  // console.log("axios datata", axiosData);
  if (axiosData.data.status === "completed") {
    //  let respArray = [];
    // console.log("axios data in complete", axiosData.data);
    // startData = [];
    startData["sttData"] = axiosData.data.results;
    // respArray.push({
    //     result: axiosData.data
    // })
    // console.log("completed");
    return startData;
    // Status 502 is a connection timeout error,
    // may happen when the connection was pending for too long,
    // and the remote server or a proxy closed it
    // let's reconnect
    //   return "donene"
  } else if (axiosData.data.status === "processing") {
    // An error - let's show it
    // console.log("processing");
    //   startData.push(axiosData.data);
    //   showMessage(response.statusText);
    // Reconnect in one second
    await new Promise((resolve) => setTimeout(resolve, 5000));
    await subscribeGet(subData, ++counterr, startData);
  }
  // console.log("start data", counterr + startData);
  return {
    sttData: startData,
    // uuid: uuid,
    filename: filename,
  };
  // console.log("resp Array", respArray)
  // return respArray;
  // return respArray
  //  else {
  //   // Get and show the message
  //   let message = await subData.text();
  //   showMessage(message);
  //   // Call subscribe() again to get the next message
  //   await subscribe(subData);
  // }
};
