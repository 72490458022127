import React, { useState } from "react";
import "./Home.css";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/action";
import Icon from "@material-ui/core/Icon";

import { Card, Form, Button } from "react-bootstrap";
import axios from "axios";
import SideNav from "../sideNav/sideNav";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import FileUploader from "../UploadFile";
import TopBar from "../TopBar/topBar";
import { CircularProgress } from "@material-ui/core";
import { DEV_URL, PROD_URL } from "../../../api";
import { subscribeGet } from "../../../helpers/subscribe";
let ngurl = "https://31b8cad7b6be.ngrok.io";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}));
// import { Card } from "@material-ui/core";

const Home = (props) => {
  React.useEffect(() => {
    let id = window.location.pathname;
    if (id.substr(1) === "home" || id.substr(1) === "") {
      setDemoFormStyle("homeContactPage");
    } else {
      setDemoFormStyle("ContactPage");
    }
  });
  const classes = useStyles();
  const { register, handleSubmit, watch, errors } = useForm();

  const [demoFormStyle, setDemoFormStyle] = React.useState("other");
  const [disable, setDisable] = useState(false);
  const [extractCircle, setExtractCircle] = useState(false);
  const [extractTick, setExtractTick] = useState(false);
  const [transcriptCircle, setTranscriptCircle] = useState(false);
  const [transcriptTick, setTranscriptTick] = useState(false);
  const [uploadCircle, setUploadCircle] = useState(false);
  const [uploadTick, setUploadTick] = useState(false);
  const [completeTick, setCompleteTick] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [values, setValues] = React.useState({
    name: "",
    Title: "",
    Description: "",
  });
  const onSubmit = async (data) => {
    props.setSideNav(true, "block");
    var property = document
      .getElementById("upload")
      .setAttribute("disabled", "disabled");

    var element = document.getElementById("videoUploaded");
    element.innerText = "";
    setUploadCircle(false);
    setUploadTick(false);
    element = document.getElementById("extractAudio");
    element.innerText = "";
    setExtractCircle(false);
    setExtractTick(false);
    element = document.getElementById("Transcript");
    element.innerText = "";
    setTranscriptCircle(false);
    setTranscriptTick(false);
    element = document.getElementById("finalmsg");
    element.innerText = "";
    setCompleteTick(false);

    const formData = new FormData();
    // console.log("values", values);
    const extension =
      selectedFile.name.split(".")[selectedFile.name.split(".").length - 1];
    const fileName = values.name + new Date().getTime() + "." + extension;
    // console.log("filename", fileName);
    formData.append("video", selectedFile, fileName);
    // formData.append('name', fileName);
    var element = document.getElementById("videoUploaded");
    element.innerText = "Uploading Video";
    setUploadCircle(true);
    // setCircle(true)

    // console.log(formData);
    let uploadResults;
    await axios.post(PROD_URL + "/upload", formData).then((data) => {
      uploadResults = data;
    });
    var element = document.getElementById("videoUploaded");
    element.innerText = "Video Uploaded";
    setUploadCircle(false);
    setUploadTick(true);
    setExtractCircle(true);
    // console.log("uploadResults", uploadResults, uploadResults.data.videoName);
    element = document.getElementById("extractAudio");
    element.innerText = "Extracting Audio";
    let extractAudioResults;
    await axios
      .post(PROD_URL + "/extractAudio", {
        videoName: uploadResults.data.videoName,
      })
      .then((data) => {
        extractAudioResults = data;
      });
    setExtractCircle(false);
    setExtractTick(true);
    setTranscriptCircle(true);

    element = document.getElementById("extractAudio");
    element.innerText = "Audio Extracted";
    element = document.getElementById("Transcript");
    element.innerText = "Transcripting Audio";
    // console.log("extractAudioResults", extractAudioResults);
    // let extractedAudio = extractAudioResults.data.videoName;
    // let audioName =
    var element = document.getElementById("extractAudio");
    element.innerText = "Audio Extracted";

    //STT
    const resp = await axios.post(PROD_URL + "/sttibm", {
      videoName: extractAudioResults.data.videoName,
      name: values.name,
    });

    // console.log("ststststtttt", resp);

    let counterData = 0;
    let startingData = {};

    let subscribeData = await subscribeGet(
      resp.data.response.sttDetails,
      counterData,
      startingData,
      // axiosResp.data.uuid,
      resp.data.response.audioPath
    );
    // console.log("finished subscribe", subscribeData);

    let dataManipulationResp = await axios.post(
      PROD_URL + "/dataManipulation",
      {
        videoName: extractAudioResults.data.videoName,
        name: values.name,
        // title: values.Title,
        subscribeData: subscribeData,
      }
    );

    // console.log("data manipulation results", dataManipulationResp);

    setTranscriptCircle(false);
    setTranscriptTick(true);
    element = document.getElementById("Transcript");
    element.innerText = "Audio Transcripted";
    var property = document.getElementById("upload");
    property.disabled = false;
    element = document.getElementById("finalmsg");
    element.innerText = "Transcription is available from the Left Panel";
    setCompleteTick(true);

    //   console.log("sttResults", sttResults);
    // props.getAllVideos();

    document.FormDemo.reset();
    props.setSideNav(false, "none");

    // var element = document.getElementsByClassName("thankmsg");
    // element[0].innerText = "Thanks, your video is uploaded";
  };
  const handleChange = (event, prop) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleFileSelect = (file) => {
    // console.log("file in handlefileselect", file);
    setSelectedFile(file);
  };

  return (
    <div>
      <TopBar title={"Deep Aspect"}></TopBar>
      <div className="dashboardParent  col-md-12 " style={{ paddingRight: 0 }}>
        {/* <div className="row" style={{ backgroundColor: "#00000", marginRight: 0 }}> */}

        <div
          className="sidenav col-sm-2 "
          style={{
            //   borderRight: "2px #80808080 solid",
            //   height: "100vh",
            paddingRight: 0,
            //   color: "white",
            //   backgroundColor: "#636161"
          }}
        >
          <SideNav></SideNav>
        </div>

        <div className="cardList row ">
          <section
            className="contactForm"
            style={{ display: "inline", margin: "auto" }}
          >
            <Card>
              <Card.Title>Upload video</Card.Title>
              <div style={{ textAlign: "center" }}>
                <form
                  name="FormDemo"
                  className="ContactPage"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <input
                    className="formControl"
                    type="text"
                    required
                    placeholder="Name *"
                    onChange={(e) => handleChange(e, "name")}
                  ></input>

                  <FileUploader
                    onFileSelectSuccess={handleFileSelect}
                    onFileSelectError={({ error }) => alert(error)}
                  />

                  <div className="buttonDiv">
                    <Button
                      name="Button"
                      id="upload"
                      type="submit"
                      className="uploadButton"
                      style={{ padding: 5, width: "100%" }}
                    >
                      Upload
                    </Button>
                  </div>
                </form>
                {/* <div className="buttonDiv">
                  <Link to ='/allVideos'>
                    <Button
                      // id="upload"
                      // type="submit"
                      className="uploadButton"
                      style={{ padding: 5, width: "100%" }}
                    >
                      Checkout uploaded Videos
                    </Button>
                    </Link>
                  </div> */}
                <div style={{ display: "inline-grid", marginLeft: "10px" }}>
                  <div className="loading">
                    <div
                      id="videoUploaded"
                      style={{ color: "#00000", margin: "5px" }}
                    >
                      <p></p>
                    </div>{" "}
                    {uploadCircle === true ? (
                      <CircularProgress
                        style={{ height: "1rem", width: "1rem", margin: "5px" }}
                      />
                    ) : null}
                    {uploadTick === true ? (
                      <Icon style={{ color: "green", margin: "5px" }}>
                        check_circle_outline_two_tone
                      </Icon>
                    ) : null}
                  </div>
                  <div className="loading">
                    <div
                      id="extractAudio"
                      style={{ color: "#00011f", margin: "5px" }}
                    >
                      <p></p>
                    </div>
                    {extractCircle === true ? (
                      <CircularProgress
                        style={{ height: "1rem", width: "1rem", margin: "5px" }}
                      />
                    ) : null}
                    {extractTick === true ? (
                      <Icon style={{ color: "green", margin: "5px" }}>
                        check_circle_outline_two_tone
                      </Icon>
                    ) : null}
                  </div>
                  <div className="loading">
                    <div
                      id="Transcript"
                      style={{ color: "#00011f", margin: "5px" }}
                    >
                      <p></p>
                    </div>
                    {transcriptCircle === true ? (
                      <CircularProgress
                        style={{ height: "1rem", width: "1rem", margin: "5px" }}
                      />
                    ) : null}
                    {transcriptTick === true ? (
                      <Icon style={{ color: "green", margin: "5px" }}>
                        check_circle_outline_two_tone
                      </Icon>
                    ) : null}
                  </div>
                  <div className="loading">
                    <div
                      id="finalmsg"
                      style={{ color: "#00011f", margin: "5px" }}
                    >
                      <p></p>
                    </div>
                    {completeTick === true ? (
                      <Icon style={{ color: "green", margin: "5px" }}>
                        check_circle_outline_two_tone
                      </Icon>
                    ) : null}
                  </div>
                </div>
              </div>
            </Card>
          </section>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allVideos: state.reducer.allVideos,
  };
};

export default connect(mapStateToProps, actionTypes)(Home);
