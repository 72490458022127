import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../Store/Action/action";
import Icon from "@material-ui/core/Icon";
import { Card, Form, Button } from "react-bootstrap";

import SideNav from "../sideNav/sideNav";

import TopBar from "../TopBar/topBar";

import "./summary.css";

const Summary = (props) => {
  const reff = React.useRef(null);
  useEffect(() => {
    props.getAllVideos();
    reff.current.scrollIntoView();
  }, []);

  return (
    <div
      ref={(el) => {
        reff.current = el;
      }}
    >
      <TopBar title={"Deep Aspect"}></TopBar>
      <div className="dashboardParent  col-md-12 " style={{ paddingRight: 0 }}>
        <div
          className="sidenav col-sm-2 "
          style={{
            paddingRight: 0,
          }}
        >
          <SideNav></SideNav>
        </div>
      </div>
      <div style={{ backgroundColor: "white" }}>
        <div style={{ backgroundColor: "#f8f6f6" }}>
          <h5
            style={{
              marginLeft: "100px",
              padding: "14px 0",
              textAlign: "center",
            }}
          >
            Summary Navigation Table{" "}
          </h5>
        </div>
        {props.allVideos.map((videos, i) => {
          let k = 0;
          return (
            <div>
              <Card>
                <div
                  style={{
                    width: "100%",
                    height: "50px",
                    backgroundColor: "#0747a6",
                    borderRadius: "20px 20px 0 0",
                  }}
                >
                  <Card.Title
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "12px",
                    }}
                  >
                    Candidate: {`${videos.name}`}
                  </Card.Title>
                </div>
                <div>
                  {videos &&
                    videos.transcription.map((trans, t) => {
                      if (
                        trans.transType &&
                        trans.transType.split("-")[1].split("")[0] === "Q"
                      ) {
                        k = k + 1;
                        return (
                          <main>
                            <ol class="gradient-list">
                              <li>
                                {" "}
                                <Link
                                  to={{
                                    pathname: "/",
                                    extraProps: {
                                      videoName: videos.name,
                                      transType: trans.transType,
                                    },
                                  }}
                                >{`Q${k}: ${trans.transcription}`}</Link>
                              </li>
                            </ol>
                          </main>
                        );
                      }
                    })}
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allVideos: state.reducer.allVideos,
  };
};

export default connect(mapStateToProps, actionTypes)(Summary);
