import React, { useRef } from "react";

const FileUploader = (props) => {
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    // handle validations
    const file = e.target.files[0];
    // console.log("file",e.target.files)

    props.onFileSelectSuccess(file);
  };

  return (
    <div className="file-uploader" style={{ margin: "20px 0 20px 0px" }}>
      <input
        type="file"
        required
        onChange={handleFileInput}
        placeholder="Browse..."
      />

      <button
        style={{ display: "none" }}
        onClick={(e) => {
          fileInput.current && fileInput.current.click();
          console.log("clickedddd");
        }}
        className="btn btn-primary"
      />
    </div>
  );
};
export default FileUploader;
