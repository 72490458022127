const initialState = {
  allVideos: [],
  disable:false,
  show:"none",
  dataArray:[],
  videosChecked:{},
  groupChecked:[],
  selectedTranscript:"none",
  queryWords:{},
  trimmedVideosData:{},
  highlights:[]
  

};
const reducer = (state = initialState, action) => {

  switch (action.type) {
    case "ALL_Videos":
      return {
        ...state,
        allVideos: action.allVideos
      };
      case "Disable_sideNav":
        return {
          ...state,
         disable: action.disable,
         show:action.show
        };
      case "DATA_ARRAY":
        return {
          ...state,
          dataArray:action.dataArray
        };
      case "VIDEOS_CHECKED":
        return{
          ...state,
          videosChecked:action.videosChecked
        }
        case "SET_QUERY_WORDS":
        return {
          ...state,
          queryWords:action.queryWords
        }
        case "GROUP_CHECKED":
        return{
          ...state,
          groupChecked:action.groupChecked
        }
        case "SET_TRIMMED_VIDEOS":
          return{
            ...state,
            trimmedVideosData:action.trimmedVideosData
          }
        case "SET_HIGHLIGHT":
          return {
            ...state,
            highlights: action.highlights  
          }
        
   
      default:
        return state;
  }
  
};
export default reducer;
