import React, { useState, useEffect } from "react";
import "./AllVideos.css";
import { connect } from "react-redux";
import SideNav from "../sideNav/sideNav";
import { Link } from "react-router-dom";
import * as actionTypes from "../../Store/Action/action";
import Icon from "@material-ui/core/Icon";
import axios from "axios";
import Popup from "../Popup/popup";

import TopBar from "../TopBar/topBar";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "react-bootstrap";
import PageHeader from "./PageHeader";
import { DEV_URL, PROD_URL } from "../../../api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const AllVideos = (props) => {
  const classes = useStyles();
  const [warning, setWarning] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
  const [videos, setVideos] = useState({});
  const [noVideo, setNoVideo] = useState(true);
  const [elRefs, setElRefs] = React.useState([]);
  const [check, setChecked] = React.useState([]);
  const [changeGroup, setChangeGroup] = React.useState([]);
  const [answerView, setAnswer] = useState();
  const [checkPoint, setCheckPoint] = useState();
  const [openPopup, setPopup] = useState(false);

  const [query, setQuery] = useState("");
  const [editNamePopup, setEditNamePopup] = useState(false);
  const [speakerName, setSpeakerName] = useState("");
  const [editPrevDetails, setEditPrevDetails] = useState([]);
  const [editWordPrevDetails, setEditWordPrevDetails] = useState({});
  const [editWordPopup, setEditWordPopup] = useState(false);

  const [selectionStartOnMouseDown, setSelectionStartOnMouseDown] =
    useState("");
  const [selectionEndOnMouseUp, setSelectionEndOnMouseUp] = useState("");
  const [selectionVideoID, setSelectionVideoID] = useState("");

  const [localTrimmedVideos, setLocalTrimmedVideos] = useState([]);
  const [generatingTrim, setGeneratingTrim] = useState(true);

  const refs = React.useRef([]);
  const transRefs = React.useRef();
  const QRefs = React.useRef();

  useEffect(() => {
    // console.log("ALLVIDEOS COMPONENT is MOUNTED", props);
    if (props.queryWords) {
      // console.log(props.queryWords);
    }
    if (Object.keys(props.videosChecked).length == 0) {
      // console.log("Nothing Checked")
    } else if (Object.keys(props.videosChecked).length > 0) {
      // console.log("VIDEOS CHECKED ARE",props.videosChecked)
      // setVideos(props.videosChecked)
    } else if (
      props.location &&
      props.location.state &&
      props.location.state.fromHighlights &&
      props.location.state.fromHighlights == true
    ) {
      let fromHighlightsPage = true;
    }

    // console.log("PROPS IN ALL VIDEOS",props)
    props.getAllVideos();

    refs.current = new Array(props.allVideos.length);
    transRefs.current = new Array();

    QRefs.current = new Array();
    // console.log("here before span highlight");
  }, []);
  useEffect(() => {
    setWarning(true);
  }, [warningMsg]);

  useEffect(() => {
    // console.log("warning set")
  }, [warning]);

  const highlighter = (e) => {
    const a = e.target.parentElement.children[0].id;

    if (document.getElementById(a).style.backgroundColor === "unset") {
      document.getElementById(a).style.backgroundColor = "#4BC8C7";
    } else {
      const a = e.target.parentElement.children[0].id;

      document.getElementById(a).style.backgroundColor = "unset";
    }
  };

  useEffect(() => {
    if (props.location.highProps) {
      let name = props.location.highProps.videoName;
      let quesType = props.location.highProps.transType;
      let ansNo = quesType.split("-")[1].substring(1);

      setAnswer(quesType);

      let transLength;
      props &&
        props.allVideos &&
        props.allVideos.some((videos) => {
          // console.log("videoeos", videos);
          if (videos.name === name) {
            transLength = videos.transcription.length;
            return true;
          }
        });
      // console.log("transLength", transLength);
      transRefs.current = new Array(transLength);

      setNoVideo(false);

      // console.log("setVideos state", videos);
      setVideos({ ...videos, [name]: true });

      // console.log("transRefs", transRefs.current);
      setElRefs(transRefs);
    }
  }, [props.location.highProps]);

  useEffect(() => {
    if (props.location.extraProps) {
      let name = props.location.extraProps.videoName;
      let quesType = props.location.extraProps.transType;
      let ansNo = quesType.split("-")[1].substring(1);

      // console.log("ansno", ansNo);
      let ansType = `C1-A${ansNo}`;
      setAnswer(ansType);

      let transLength;
      props &&
        props.allVideos &&
        props.allVideos.some((videos, i) => {
          // console.log("videoeos", videos);
          if (videos.name === name) {
            transLength = videos.transcription.length;
            setCheckPoint(i);
            return true;
          }
        });
      // console.log("transLength", transLength);
      transRefs.current = new Array(transLength);

      setChecked(refs);

      setNoVideo(false);

      // console.log("setVideos state", videos);
      setVideos({ ...videos, [name]: true });

      setElRefs(transRefs);
    }
  }, [props.location.extraProps]);

  const allfunc = async () => {
    const videosSet = [];
    const videosKeys = Object.keys(videos);

    for (let i = 0; i < videosKeys.length; i++) {
      if (videos[videosKeys[i]] === true) {
        videosSet.push(videosKeys[i]);
      }
    }

    const matchingHighlightedTranscripts = [];
    if (
      props.allVideos &&
      props.allVideos.length > 0 &&
      videosSet.length > 0 &&
      query != ""
    ) {
      let similars = [];
      try {
        // let Dev_bakcend="https://008e5d99f090.ngrok.io/similarwords"
        let prod_backend =
          "https://similarwords-316917.uc.r.appspot.com/similarwords";
        // let ngrok_backend="https://195220fed93a.ngrok.io/similarwords"

        let x = await axios.post(prod_backend, { query: query });
        // console.log("XXXXX", x);

        if (x.data.error) {
          // console.log("no such word found in backend");
          // return setWarningMsg("No match found")
        } else {
          for (let i = 0; i < x.data.syns.length; i++) {
            if (x.data.syns[i][1] > 0.5) {
              similars.push(x.data.syns[i][0]);
            }
          }
        }
      } catch {
        // console.log("couldnt connect to the backend");
        similars = [];
      }

      // for(let i=0;i<videosSet.length;i++){

      for (let j = 0; j < props.allVideos.length; j++) {
        if (videosSet.includes(props.allVideos[j].name)) {
          for (let k = 0; k < props.allVideos[j].transcription.length; k++) {
            if (
              props.allVideos[j].transcription[k].transcription
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              similars.some((syn) =>
                props.allVideos[j].transcription[k].transcription
                  .toLowerCase()
                  .includes(syn)
              )
            ) {
              let highlightedTranscript = {
                transcriptId: props.allVideos[j].transcription[k]._id, //transcriptid is fine
                videoName: props.allVideos[j].name, //video name is fine
                transType: props.allVideos[j].transcription[k].transType, //transtype is fine i think
                speaker: props.allVideos[j].transcription[k].speaker, //speakerName should be passed as well/instead
                transcript: props.allVideos[j].transcription[k].transcription,
                wordsArray: props.allVideos[j].transcription[k].wordsArray,
                speakerNameOne: props.allVideos[j].videoSpeakerOne,
                speakerNameTwo: props.allVideos[j].videoSpeakerTwo,
                // speakerName:props.allVideos[j].transcription[k].speakerName
                //pass
              };
              matchingHighlightedTranscripts.push(highlightedTranscript);
              // matchingTranscripts.push(props.allVideos[j].transcription[k]._id)
            }
          }
        }
      }
      //}

      if (matchingHighlightedTranscripts.length == 0) {
        return setWarningMsg("Nothing found for Your Search");
      }
      // setMatchedTranscripts(filteredMatchingTranscripts)

      ///
      const uniqueHighlights = matchingHighlightedTranscripts;

      const data = {};
      if (uniqueHighlights) {
        uniqueHighlights.map((item, index) => {
          if (data[item.videoName]) {
            data[item.videoName].push(item);
          } else {
            data[item.videoName] = [item];
          }
        });
      }

      // console.log("DATA",data)

      const dataArray = [];
      for (let key in data) {
        let value = data[key];
        dataArray.push(value);
      }
      //
      const queryWords = {};
      queryWords["keyword"] = query;
      queryWords["similars"] = similars;
      // console.log("QUERY WORDS OBJECT IN ALLVIDEO",queryWords)
      props.setQueryWords(queryWords);
      props.setVideosSet(videos);
      // console.log("DATA ARRAY IN ALLVIDEOS",dataArray)
      props.history.push({
        pathname: "/Highlights",
        state: { data: dataArray, queryWords: queryWords },
      });
    }
  };

  useEffect(() => {
    allfunc();

    spanHighlight();
    // setVideos(props.allVideos);
  }, [videos, query]);

  useEffect(() => {
    if (check && check.current && check.current.length > 0) {
      document.getElementById(`checkIt${checkPoint}`).checked = false;
      // console.log("checkkkkkkk",document.getElementById(`checkIt${checkPoint}`).checked)
    }
  }, [check]);

  useEffect(() => {
    if (elRefs.current) {
      // console.log("answerView", answerView);
      if (document.getElementById(answerView)) {
        document
          .getElementById(answerView)
          .scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [elRefs]);

  const handleMove = (idd) => {
    // console.log("videooooosssschecckkkk in move", idd);

    const videosSet = [];
    let group = [];
    let count = 0;
    const videosKeys = Object.keys(videos);

    for (let i = 0; i < videosKeys.length; i++) {
      if (videos[videosKeys[i]] === true) {
        videosSet.push(videosKeys[i]);
        count++;
      }
    }
    if (count === 0) {
      // console.log("count is 0", count);

      document.getElementById(idd).innerText = "Please select a video ";
      document.getElementById(idd).style.color = "red";
      document.getElementById(idd).style.display = "block";
    } else {
      // console.log("count not 0", count);
      let p = document.getElementById(idd);
      // console.log("P IS", p);
      if (p) {
        document.getElementById(idd).style.display = "none";
        // document.getElementById(idd).style.color=
      }
      for (let j = 0; j < props.allVideos.length; j++) {
        if (videosSet.includes(props.allVideos[j].name)) {
          // console.log("propss", props.allVideos[j]);
          //setChangeGroup([...changeGroup,props.allVideos[j]])
          group.push(props.allVideos[j]);
          // console.log("jjjjjjjjjjj", changeGroup);
        }
      }
      props.setGroupCheck(group);

      setPopup(!openPopup);
    }
  };

  const handleChange = (event) => {
    // console.log("change")
    setNoVideo(false);
    setVideos({ ...videos, [event.target.name]: event.target.checked });
  };

  const setColour = () => {
    if (!document.getElementsByClassName("marked").length > 0) {
      if (
        document.getElementsByClassName("paras").length > 0 &&
        props.location.highProps
      ) {
        if (props.queryWords && props.queryWords.keyword) {
          // console.log(props.queryWords);
          document.getElementById("queryInputBox").value =
            props.queryWords.keyword;
        }
        if (
          (props.location.highProps && props.location.highProps.transId) ||
          props.selectedTranscript !== "none"
        ) {
          if (
            props.location.highProps &&
            props.location.highProps.transId &&
            props.location.highProps.transId !== props.selectedTranscript
          ) {
            // actionTypes.setSelectedTranscript(props.location.highProps.transId)
          }

          // console.log("transtypeee", props.location.highProps);
          //  console.log("" )
          // console.log(document.getElementsByClassName('paras'))
          let x = document.getElementById(props.location.highProps.transId);
          //  console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",x)
          let wordsArray = [];
          wordsArray.push(props.queryWords.keyword);
          wordsArray = [...wordsArray, ...props.queryWords.similars];
          // console.log("words ARRRRRRRRRRRAY", wordsArray);
          for (let j = 0; j < wordsArray.length; j++) {
            let regex = new RegExp(`${wordsArray[j]}`, "gi");
            x.innerHTML = x.innerHTML.replace(
              regex,
              (match) => `<mark class="marked" >${match}</mark>`
            );
          }
        }
      } else {
        return;
      }
    }
  };

  const handleSearchClick = (e) => {
    // console.log("handle search")
    e.preventDefault();
    const videosSet = [];

    const videosKeys = Object.keys(videos);

    for (let i = 0; i < videosKeys.length; i++) {
      if (videos[videosKeys[i]] === true) {
        videosSet.push(videosKeys[i]);
      }
    }
    if (videosSet.length == 0) {
      // console.log("PLEASE SELECT A VIDEO FIRST")
      return setWarningMsg("Please select a video first");
      //  alert("select a video first")
    }
    const inputValue = document
      .getElementById("queryInputBox")
      .value.trim()
      .toLowerCase();
    if (inputValue == "") {
      // console.log("empty input")
      return setWarningMsg("Please enter something");

      // return alert('try something else')
    } else {
      // setWarningMsg(false)
      setWarning(false);
    }
    // console.log("INPUT BOX VALUE IS",inputValue)
    setQuery(inputValue);
  };

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: "#5aaeea08",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
    },
  })(MuiAccordionSummary);

  const handleWordClick = (e, videoId) => {
    // console.log("E is",e.target)

    let y = parseFloat(e.target.getAttribute("data-from"));
    if (y) document.getElementById(videoId).currentTime = y;
  };

  const handleTimeUpdate = (e, videoId) => {
    try {
      document
        .querySelector(".highlightTomato")
        .classList.remove("highlightTomato");
    } catch {}
    let allWords = document.querySelectorAll(`[data-vidid="${videoId}"]`);
    // console.log("allWords",allWords)
    const currTime = document.getElementById(`${videoId}`).currentTime;
    let flooredCurrTime = Math.floor(currTime);
    let flooredMatches = [];
    // let flooredMatches=allWords.map((word)=>{
    //   return Math.floor(Number(word.getAttribute("data-from")))===flooredCurrTime
    // })
    for (let i = 0; i < allWords.length; i++) {
      if (
        Math.floor(Number(allWords[i].getAttribute("data-from"))) ===
        flooredCurrTime
      ) {
        flooredMatches.push(allWords[i]);
      }
    }
    // console.log(flooredMatches)
    if (flooredMatches.length > 0) {
      let smallestDiffWord = flooredMatches[0];
      let smallestDiff = Math.abs(
        Number(smallestDiffWord.getAttribute("data-from")) - currTime
      );

      for (let j = 1; j < flooredMatches.length; j++) {
        if (
          flooredMatches[j] &&
          Math.abs(
            Number(flooredMatches[j].getAttribute("data-from")) - currTime
          ) < smallestDiff
        ) {
          smallestDiffWord = flooredMatches[j];
        }
      }
      // console.log("Smallest DIFF",smallestDiffWord.innerText)
      smallestDiffWord.classList.add("highlightTomato");
      let paragraphId = smallestDiffWord.parentNode.parentNode;
      // console.log("sdhfskdhfbshdfhbhjsdfhsgdfhgsdyfgv", paragraphId);
      document
        .getElementById(paragraphId.id)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const spanHighlight = () => {
    try {
      let x = document.querySelectorAll("span.red");
      // console.log("span highlightssss",x)
      for (let i = 0; i < x.length; i++) {
        x[i].classList.remove("red");
      }
    } catch {
      // console.log("inside cath in span");
    }
    // props.getAllVideos()
    if (props.highlights.length == 0) {
      // document.querySelector('span.red').classList.remove('red')
    }
    if (props.highlights.length > 0) {
      // console.log("props.highlights greater", props.highlights);
      let highlightablespans = [];

      for (let i = 0; i < props.highlights.length; i++) {
        let selectionVideoId = props.highlights[i].videoId;
        let startTime = props.highlights[i].dataFrom;
        let endTime = props.highlights[i].dataEnd;
        let selectionspans = document.querySelectorAll(
          `[data-vidid="${selectionVideoId}"]`
        );
        // console.log("selectionSpans",selectionspans)
        let filterselectionspans = [];
        // console.log("spans with video id",selectionspans)
        // console.log("selectionspans",selectionspans[0])
        for (let i = 0; i < selectionspans.length; i++) {
          if (
            Number(selectionspans[i].getAttribute("data-from")) >=
              Number(startTime) &&
            Number(selectionspans[i].getAttribute("data-to") <= Number(endTime))
          )
            filterselectionspans.push(selectionspans[i]);
        }

        for (let j = 0; j < filterselectionspans.length; j++) {
          // filterselectionspans[j].style.color="red"
          filterselectionspans[j].classList.add("red");
        }
        // console.log("filterSelection spans",filterselectionspans)
      }

      // console.log("filterspans",filterselectionspans)
    }
  };

  useEffect(() => {
    if (props.highlights.length == 0) {
      // console.log("highlights length 0");

      try {
        let redSpans = document.querySelectorAll("span.red");
        for (let i = 0; i < redSpans.length; i++) {
          redSpans[i].classList.remove("red");
        }
      } catch {
        // console.log("props.highlights in catch", props.highlights);
      }
    }
    // console.log("props.highlights",props.highlights)
    if (props.highlights.length > 0) {
      spanHighlight();
    }
  }, [props.highlights]);

  useEffect(() => {
    // console.log("EDIT PREV DETAILS USE EFFECT",editPrevDetails)
  }, [editPrevDetails]);

  useEffect(() => {
    // console.log("localtrimmedvideos",localTrimmedVideos)
    if (Object.keys(localTrimmedVideos).length >= 0) {
      props.setTrimmedVideos(localTrimmedVideos);
      setGeneratingTrim(!generatingTrim);
    }
  }, [localTrimmedVideos]);

  const handleEditName = (e, videoId, transcriptId, speaker) => {
    // console.log("speaker is", speaker);
    // console.log("video Id",videoId)
    // console.log("transcriptId",transcriptId)
    setEditNamePopup(true);
    setEditPrevDetails([videoId, transcriptId, speaker]);
  };
  const handleEditWordSubmit = async (e) => {
    e.preventDefault();

    let x = document.getElementById("editWordInput").value;
    if (x == "") {
      return alert("type something in there");
    } else {
      // console.log("new word", x);
      // console.log("previous word details", editWordPrevDetails);

      await axios.post(PROD_URL + "/updateWord", {
        videoId: editWordPrevDetails.videoId,
        transcriptionIndex: editWordPrevDetails.transcriptionIndex,
        wordIndex: editWordPrevDetails.wordIndex,
        wordId: editWordPrevDetails.wordId,
        word: x,
      });
      setEditWordPopup(false);
      props.getAllVideos();
    }
  };

  const handleEditNameSubmit = async (e) => {
    e.preventDefault();

    let x = document.getElementById("editNameInput").value;
    if (x == "") {
      return alert("type something in there");
    } else {
      // console.log("new name", x);
      // console.log("previous names", editPrevDetails);
      // await axios.post('http://localhost:5000/updateSpeaker',{
      //   videoId:editPrevDetails[0],
      //   transcriptId:editPrevDetails[1],
      //   updatedSpeakerName:x
      // })
      await axios.post(PROD_URL + "/updateAllSpeakerInstances", {
        videoId: editPrevDetails[0],
        updatedSpeakerName: x,
        speakerNumber: editPrevDetails[2],
      });
      setEditNamePopup(false);
      props.getAllVideos();
    }
  };

  const handleWordEditClick = (
    videoId,
    transcriptionIndex,
    wordIndex,
    wordId,
    word
  ) => {
    // console.log("transcription index",transcriptionIndex)
    // console.log("wordIndex",wordIndex)
    // console.log("clicked word id",wordId)
    // console.log("Word clicked",word)

    let wordPrevDetailObj = {
      videoId: videoId,
      transcriptionIndex: transcriptionIndex,
      wordIndex: wordIndex,
      wordId: wordId,
      word: word,
    };
    // console.log("WORDPREVDETAILOBJ", wordPrevDetailObj);
    setEditWordPrevDetails(wordPrevDetailObj);
    // setEditWordPopup(true)
  };

  const handleRemoveSelection = async (e, videoId, objectId) => {
    // console.log("videoId", videoId);
    // console.log("objectid", objectId);
    let allSelectedHighlights = await axios.post(
      PROD_URL + "/removeSelection",
      {
        objectId: objectId,
        videoId: videoId,
      }
    );
    // console.log("all selected highlights", allSelectedHighlights);
    props.setHighlight(allSelectedHighlights.data.allSelectedHighlights);
    // props.getAllVideos()
  };

  useEffect(() => {
    // console.log("inside word edit");
    if (editWordPrevDetails.videoId) {
      // console.log("edit word prev details set", editWordPrevDetails);
      setEditWordPopup(true);
    }
  }, [editWordPrevDetails]);

  return (
    <div>
      <TopBar title={"Deep Aspect"}></TopBar>
      <PageHeader></PageHeader>

      <div className="dashboardParent  col-md-12 " style={{ paddingRight: 0 }}>
        <div
          className="sidenav col-sm-2 "
          style={{
            paddingRight: 0,
          }}
        >
          <SideNav></SideNav>
        </div>
        <div style={{ backgroundColor: "#f1f1f1" }}>
          <h5
            style={{
              marginLeft: "85px",
              padding: "14px 0",
              textAlign: "center",
            }}
          >
            Select a video from the Left Panel{" "}
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: 90,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              width: "100%",
            }}
          >
            <input
              type="text"
              placeholder="Search"
              id="queryInputBox"
              style={{ width: "25%" }}
            ></input>
            <button
              type="submit"
              className="searchButton"
              onClick={(e) => {
                handleSearchClick(e);
              }}
            >
              <Icon style={{ marginRight: 6 }}>search_icon</Icon>
            </button>
            {/* <button onClick={(e)=>{handleAllSelect(e)}}>select all</button> */}
          </div>
          {editNamePopup ? (
            <div
              style={{
                position: "fixed",
                backgroundColor: "rgba(250,250,250,0.9)",
                width: "100%",
                height: "100vh",
                top: 0,
                left: 0,
                zIndex: 1000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "relative", maxHeight: "auto" }}>
                <h5>Edit Speaker Name</h5>
                <input
                  type="text"
                  id="editNameInput"
                  placeholder="Speaker Name"
                ></input>
                <button
                  style={{ marginLeft: 3, marginRight: 3 }}
                  onClick={(e) => handleEditNameSubmit(e)}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setEditNamePopup(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : null}
          {editWordPopup ? (
            <div
              style={{
                position: "fixed",
                backgroundColor: "rgba(250,250,250,0.9)",
                width: "100%",
                height: "100vh",
                top: 0,
                left: 0,
                zIndex: 1000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "relative", maxHeight: "auto" }}>
                <h5>Edit transcript word</h5>
                <input
                  type="text"
                  id="editWordInput"
                  placeholder="Enter new word here"
                ></input>
                <button
                  style={{ marginLeft: 3, marginRight: 3 }}
                  onClick={(e) => handleEditWordSubmit(e)}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setEditWordPopup(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : null}

          <div>
            {warning && (
              <h5 style={{ color: "tomato", fontSize: "12px", marginTop: 5 }}>
                {warningMsg}
              </h5>
            )}
          </div>
        </div>
        <div className="accordian">
          <div className="buttonDiv" style={{ marginTop: "16px" }}>
            <Link to="/upload">
              <Button
                id="upload"
                type="submit"
                className="uploadButton"
                style={{ padding: 5, width: "94%" }}
                // onClick={handleCheckBox}
              >
                Upload a Video
              </Button>
            </Link>
          </div>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Group 1</Typography>
            </AccordionSummary>
            <Button
              className="uploadButton"
              style={{ marginLeft: 40, padding: "0 30px", marginTop: 5 }}
              onClick={() => handleMove("move1")}
            >
              Move
            </Button>
            <div>
              {" "}
              <p id="move1" className="warn"></p>{" "}
            </div>
            {openPopup ? (
              <Popup content={["2", "3"]} handleClose={setPopup}></Popup>
            ) : null}
            <AccordionDetails style={{ paddingTop: "0" }}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {/* <button style={{fontSize:10,fontWeight:"thin",borderWidth:1}} onClick={(e)=>{handleAllSelect(e)}}>select all</button> */}
                  {props &&
                    props.allVideos &&
                    props.allVideos.map((video, i) => {
                      {
                        /* console.log("one by one",video) */
                      }
                      // return {video["group"]==="1"?}
                      if (video.group === "1") {
                        return (
                          <FormControlLabel
                            control={
                              <input
                                type="checkbox"
                                id={`checkIt${i}`}
                                //  checked={true}
                                checked={videos[video.name]}
                                onChange={handleChange}
                                name={video.name}
                                ref={(ref) => (refs.current[i] = ref)}
                                // inputRef={(ref) => (refs.current[i] = ref)}
                              />
                            }
                            label={video.name}
                          />
                        );
                      }
                    })}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>Group 2</Typography>
            </AccordionSummary>
            <Button
              className="uploadButton"
              style={{ marginLeft: 45, padding: "0 30px", marginTop: 5 }}
              onClick={() => handleMove("move2")}
            >
              Move
            </Button>
            <div>
              {" "}
              <p id="move2" className="warn"></p>{" "}
            </div>
            {openPopup ? (
              <Popup content={["1", "3"]} handleClose={handleMove}></Popup>
            ) : null}
            <AccordionDetails style={{ paddingTop: "0" }}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {props &&
                    props.allVideos &&
                    props.allVideos.map((video, i) => {
                      {
                        /* console.log("one by one",video) */
                      }
                      // return {video["group"]==="1"?}
                      if (video.group === "2") {
                        return (
                          <FormControlLabel
                            control={
                              <input
                                type="checkbox"
                                id={`checkIt${i}`}
                                //  checked={true}
                                checked={videos[video.name]}
                                onChange={handleChange}
                                name={video.name}
                                ref={(ref) => (refs.current[i] = ref)}
                                // inputRef={(ref) => (refs.current[i] = ref)}
                              />
                            }
                            label={video.name}
                          />
                        );
                      }
                    })}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>Group 3</Typography>
            </AccordionSummary>
            <Button
              className="uploadButton"
              style={{ marginLeft: 45, padding: "0 30px", marginTop: 5 }}
              onClick={() => handleMove("move3")}
            >
              Move
            </Button>
            <div>
              {" "}
              <p id="move3" className="warn"></p>{" "}
            </div>
            {openPopup ? (
              <Popup content={["1", "2"]} handleClose={handleMove}></Popup>
            ) : null}
            <AccordionDetails style={{ paddingTop: "0" }}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {props &&
                    props.allVideos &&
                    props.allVideos.map((video, i) => {
                      {
                        /* console.log("one by one",video) */
                      }
                      // return {video["group"]==="1"?}
                      if (video.group === "3") {
                        return (
                          <FormControlLabel
                            control={
                              <input
                                type="checkbox"
                                id={`checkIt${i}`}
                                //  checked={true}
                                checked={videos[video.name]}
                                onChange={handleChange}
                                name={video.name}
                                ref={(ref) => (refs.current[i] = ref)}
                                // inputRef={(ref) => (refs.current[i] = ref)}
                              />
                            }
                            label={video.name}
                          />
                        );
                      }
                    })}
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="videoContainer ">
          {props &&
            props.allVideos &&
            props.allVideos.map((video, i) => {
              {
                /* console.log("ALLVIDEOS",video) */
              }
              let interviewer;
              {
                /* {console.log("VIDEOSSSSSSSSS",videos)} */
              }
              {
                /* console.log("video.transcriptionnnnn", video.transcription); */
              }
              if (videos[video.name] === true) {
                return (
                  <div>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <video
                        onTimeUpdate={(e) => handleTimeUpdate(e, video._id)}
                        className="videoplayer"
                        id={video._id}
                        src={`https://storage.googleapis.com/deepaspectvt5/videos/${video.videoLink}`}
                        controls
                      ></video>
                    </div>
                    <button
                      style={{ marginLeft: 100, marginBottom: 20 }}
                      onClick={async (e) => {
                        // let generatingTrim;
                        // console.log("start", selectionStartOnMouseDown);
                        // console.log("end", selectionEndOnMouseUp);
                        // console.log("selected video", selectionVideoID);
                        let startTime = selectionStartOnMouseDown;
                        let endTime = selectionEndOnMouseUp;
                        let temp;

                        if (
                          selectionVideoID == "" ||
                          Number(selectionStartOnMouseDown) <= 0 ||
                          Number(selectionEndOnMouseUp) <= 0
                        ) {
                          alert("Select transcript correctly");
                          return console.log("asd");
                        } else {
                          setGeneratingTrim(!generatingTrim);
                          if (
                            Number(selectionStartOnMouseDown) >
                            Number(selectionEndOnMouseUp)
                          ) {
                            // alert('rev')
                            temp = startTime;
                            startTime = endTime;
                            endTime = temp;
                            // console.log("startTime", startTime);
                            // console.log("EndTime", endTime);
                          }
                          let allTrimmedVideos = await axios.post(
                            PROD_URL + "/trimvideo/",
                            {
                              start: startTime,
                              end: endTime,
                              videoName: selectionVideoID,
                            }
                          );
                          // console.log("alltrimmedvideos", allTrimmedVideos);
                          let filterdata = {};

                          allTrimmedVideos.data.trimmedVideos.map(
                            (trimmedVideo) => {
                              if (
                                filterdata[trimmedVideo.videoName] === undefined
                              ) {
                                filterdata[trimmedVideo.videoName] = [
                                  trimmedVideo,
                                ];
                              } else {
                                filterdata[trimmedVideo.videoName].push(
                                  trimmedVideo
                                );
                              }
                            }
                          );

                          // console.log("x",x)
                          // if(x.data && x.data.trimmedVideos)
                          // alert(x.data.videoUrl)
                          // setLocalTrimmedVideos([...localTrimmedVideos,x.data.videoUrl])
                          setLocalTrimmedVideos(filterdata);
                          // props.setHighlight({
                          //   videoId:selectionVideoID,
                          //   dataFrom:startTime,
                          //   dataEnd:endTime
                          // });
                          // console.log("add transcripts");
                          // let allWords=document.querySelectorAll(`[data-vidid="${videoId}"]`)

                          let allWords = document.querySelectorAll(
                            `[data-vidid="${selectionVideoID}"]`
                          );
                          // console.log("ALL WORDS",allWords)
                          // console.log("VALUEEE",allWords[i].childNodes[0].data)
                          let selectedWords = [];
                          for (let i = 0; i < allWords.length; i++) {
                            if (
                              Number(allWords[i].getAttribute("data-from")) >=
                                Number(startTime) &&
                              Number(
                                allWords[i].getAttribute("data-to") <=
                                  Number(endTime)
                              )
                            ) {
                              selectedWords.push(
                                allWords[i].childNodes[0].data
                              );
                            }
                          }
                          // console.log("selected wordsSSSSSSS",selectedWords)
                          let selectedWordsString = selectedWords.join(" ");

                          let addedSelectedTranscript = await axios.post(
                            PROD_URL + "/addSelectedTranscript",
                            {
                              videoId: selectionVideoID,
                              dataFrom: startTime,
                              dataEnd: endTime,
                              selectedWordsString: selectedWordsString,
                            }
                          );
                          // console.log("adding transcripts");
                          let allSelectedTranscripts = await axios.get(
                            PROD_URL + "/getSelectedtranscripts"
                          );

                          if (
                            allSelectedTranscripts.data.selectedTranscripts
                              .length >= 0
                          ) {
                            props.setHighlight(
                              allSelectedTranscripts.data.selectedTranscripts
                            );
                          }
                          spanHighlight();
                          props.history.push("/TrimmedVideos");
                        }
                      }}
                    >
                      Trim Transcript
                    </button>
                    {generatingTrim ? (
                      <span style={{ marginLeft: 5, color: "orange" }}>
                        generating
                      </span>
                    ) : null}
                    {props.highlights.length > 0 && (
                      <div style={{ marginBottom: 30 }}>
                        {" "}
                        <h5 style={{ textAlign: "center" }}>Highlights</h5>
                        {props.highlights.length > 0 &&
                          props.highlights.map((highlight) => {
                            if (highlight.videoId === video._id)
                              return (
                                <div className="selectedHighlight">
                                  <span>{highlight.selectedWordsString}</span>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      color: "red",
                                      fontSize: 11,
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      handleRemoveSelection(
                                        e,
                                        highlight.videoId,
                                        highlight._id
                                      );
                                    }}
                                  >
                                    remove
                                  </span>
                                </div>
                              );
                          })}
                      </div>
                    )}
                    <div className="transcripts">
                      {video &&
                        video.transcription &&
                        video.transcription.map((user, index) => {
                          {
                            /* console.log("useruseruseruserdatatatatatat", user); */
                          }
                          if (index === 0) {
                            interviewer = user.speaker;
                          }

                          return user.speaker === interviewer ? (
                            <div className="Transcript_all">
                              <div className="user">
                                <div className="userDetails">
                                  <img
                                    className="userImg"
                                    src="https://cdn.pixabay.com/photo/2017/07/18/23/23/user-2517433_960_720.png"
                                  />
                                  <div className="userName">
                                    <p className="titlee">
                                      {video.videoSpeakerOne}{" "}
                                      <span>
                                        <Icon
                                          style={{
                                            marginLeft: 5,
                                            fontSize: 13,
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            handleEditName(
                                              e,
                                              video._id,
                                              user._id,
                                              "one"
                                            );
                                          }}
                                        >
                                          border_color_icon
                                        </Icon>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ display: "inline-flex" }}
                                id={user.transType}
                                ref={(trans) => (QRefs.current[index] = trans)}
                              >
                                <p
                                  className="paras"
                                  style={{
                                    fontSize: "0.9rem",
                                    backgroundColor: "unset",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                  id={`${user._id}`}
                                >
                                  {/* {user.transcription} */}

                                  {user.wordsArray.map((word, wordIndex) => {
                                    return (
                                      <div>
                                        <span
                                          style={{
                                            padding: "1px 2px",
                                            position: "relative",
                                          }}
                                          className="spanWord"
                                          onMouseDown={(e) => {
                                            if (
                                              e.target.getAttribute(
                                                "data-from"
                                              ) != null
                                            ) {
                                              setSelectionStartOnMouseDown(
                                                e.target.getAttribute(
                                                  "data-from"
                                                )
                                              );
                                            } else if (
                                              e.target.getAttribute(
                                                "data-from"
                                              ) == null &&
                                              e.target.innerText == "Edit"
                                            ) {
                                              // console.log("yes", e.target);
                                              setSelectionStartOnMouseDown(
                                                e.target.parentElement.getAttribute(
                                                  "data-from"
                                                )
                                              );
                                            }

                                            // setSelectionStartOnMouseDown(e.target)
                                          }}
                                          onMouseUp={(e) => {
                                            if (
                                              e.target.getAttribute(
                                                "data-to"
                                              ) == null &&
                                              e.target.innerText == "Edit"
                                            ) {
                                              setSelectionEndOnMouseUp(
                                                e.target.parentElement.getAttribute(
                                                  "data-to"
                                                )
                                              );
                                              setSelectionVideoID(
                                                e.target.parentElement.getAttribute(
                                                  "data-vidid"
                                                )
                                              );
                                            } else if (
                                              e.target.getAttribute(
                                                "data-to"
                                              ) != null
                                            ) {
                                              setSelectionEndOnMouseUp(
                                                e.target.getAttribute("data-to")
                                              );
                                              setSelectionVideoID(
                                                e.target.getAttribute(
                                                  "data-vidid"
                                                )
                                              );
                                            }
                                          }}
                                          onClick={(e) =>
                                            handleWordClick(e, video._id)
                                          }
                                          style={{ padding: "2px 3px" }}
                                          data-vidid={video._id}
                                          id={word._id}
                                          data-from={`${word.from}`}
                                          data-to={`${word.to}`}
                                        >
                                          {word.word}
                                          <span
                                            onClick={(e) => {
                                              handleWordEditClick(
                                                video._id,
                                                index,
                                                wordIndex,
                                                word._id,
                                                word.word
                                              );
                                            }}
                                            style={{
                                              position: "relative",
                                              top: "-10px",
                                              fontSize: 10,
                                              opacity: 0.8,
                                              cursor: "pointer",
                                              padding: 1,
                                            }}
                                          >
                                            Edit
                                          </span>
                                        </span>
                                      </div>
                                    );
                                  })}
                                </p>
                                {/* <Icon onClick={(e)=>{highlighter(e)}}>border_color_icon</Icon> */}
                              </div>
                            </div>
                          ) : (
                            <div className="Transcript_all">
                              <div className="user">
                                <div className="userDetails">
                                  <img
                                    className="userImg"
                                    src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                  />
                                  <div className="userName">
                                    <p className="titlee">
                                      {video.videoSpeakerTwo}

                                      <span>
                                        <Icon
                                          style={{
                                            marginLeft: 5,
                                            fontSize: 13,
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            handleEditName(
                                              e,
                                              video._id,
                                              user._id,
                                              "two"
                                            );
                                          }}
                                        >
                                          border_color_icon
                                        </Icon>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ display: "inline-flex" }}
                                id={user.transType}
                                ref={(trans) =>
                                  (transRefs.current[index] = trans)
                                }
                                // ref={elRefs[index]}
                              >
                                <p
                                  className="paras"
                                  id={`${user._id}`}
                                  style={{
                                    fontSize: "0.9rem",
                                    backgroundColor: "unset",
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {/* {user.transcription} */}
                                  {user.wordsArray.map((word, wordIndex) => {
                                    {
                                      /* console.log("word",word.word) */
                                    }
                                    return word.word !== "" ? (
                                      <div>
                                        <span
                                          style={{
                                            position: "relative",
                                            padding: "1px 2px",
                                          }}
                                          className="eachWordSpan"
                                          onMouseDown={(e) => {
                                            if (
                                              e.target.getAttribute(
                                                "data-from"
                                              ) != null
                                            ) {
                                              setSelectionStartOnMouseDown(
                                                e.target.getAttribute(
                                                  "data-from"
                                                )
                                              );
                                            } else if (
                                              e.target.getAttribute(
                                                "data-from"
                                              ) == null &&
                                              e.target.innerText == "Edit"
                                            ) {
                                              // console.log("yes", e.target);
                                              setSelectionStartOnMouseDown(
                                                e.target.parentElement.getAttribute(
                                                  "data-from"
                                                )
                                              );
                                            }
                                            // console.log("mouseDown event",e.target);
                                            // console.log("MouseDOWN data-start",e.target.getAttribute('data-from'))
                                            // setSelectionStartOnMouseDown(e.target.getAttribute('data-from'))
                                          }}
                                          onMouseUp={(e) => {
                                            if (
                                              e.target.getAttribute(
                                                "data-to"
                                              ) == null &&
                                              e.target.innerText == "Edit"
                                            ) {
                                              setSelectionEndOnMouseUp(
                                                e.target.parentElement.getAttribute(
                                                  "data-to"
                                                )
                                              );
                                              setSelectionVideoID(
                                                e.target.parentElement.getAttribute(
                                                  "data-vidid"
                                                )
                                              );
                                            } else if (
                                              e.target.getAttribute(
                                                "data-to"
                                              ) != null
                                            ) {
                                              setSelectionEndOnMouseUp(
                                                e.target.getAttribute("data-to")
                                              );
                                              setSelectionVideoID(
                                                e.target.getAttribute(
                                                  "data-vidid"
                                                )
                                              );
                                            }

                                            // setSelectionVideoID(e.target.getAttribute('data-vidid'))

                                            // setSelectionEndOnMouseUp(e.target.getAttribute('data-to'))
                                          }}
                                          onClick={(e) =>
                                            handleWordClick(e, video._id)
                                          }
                                          style={{ padding: "2px 3px" }}
                                          id={word._id}
                                          data-from={`${word.from}`}
                                          data-to={`${word.to}`}
                                          data-vidid={video._id}
                                        >
                                          {word.word}
                                          <span
                                            onClick={(e) => {
                                              handleWordEditClick(
                                                video._id,
                                                index,
                                                wordIndex,
                                                word._id,
                                                word.word
                                              );
                                            }}
                                            style={{
                                              position: "relative",
                                              top: "-10px",
                                              fontSize: 10,
                                              opacity: 0.8,
                                              cursor: "pointer",
                                              padding: 1,
                                            }}
                                          >
                                            Edit
                                          </span>
                                        </span>
                                      </div>
                                    ) : null;
                                  })}
                                </p>
                                {/* <Icon onClick={(e)=>{highlighter(e)}}>border_color_icon</Icon> */}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {setColour()}
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allVideos: state.reducer.allVideos,
    dataArray: state.reducer.dataArray,
    videosChecked: state.reducer.videosChecked,
    queryWords: state.reducer.queryWords,
    selectedTranscript: state.reducer.selectedTranscript,
    groupChecked: state.reducer.groupChecked,
    trimmedVideosData: state.reducer.trimmedVideosData,
    highlights: state.reducer.highlights,
  };
};

export default connect(mapStateToProps, actionTypes)(AllVideos);
