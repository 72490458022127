import React, { Component } from "react";
import "./topBar.css";

import { Link } from "react-router-dom";
import color from "@material-ui/core/colors/amber";

class Navbar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="sectionTop">
        <div className="topbar-header-nav">
          <h3
            style={{
              textAlign: "center",
              fontSize: "1.8rem",
              letterSpacing: "1.1px",
              padding: "6px",
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            {this.props.title}
          </h3>
        </div>
      </section>
    );
  }
}

export default Navbar;
