import React from 'react';
import Routing from './components/Routing/routing'
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
// import Reducer from "./components/Store/Reducer/reducer";
import rootReducer from './components/Store/Reducer/combineReducer';
import thunk from "redux-thunk";



const store = createStore(rootReducer, applyMiddleware(thunk))


ReactDOM.render(<Provider store={store} >{Routing}</Provider>, document.getElementById('root'));


serviceWorker.unregister();