import axios from "axios";
import { DEV_URL, PROD_URL } from "../../../api";
// let ngurl="https://31b8cad7b6be.ngrok.io"
export function getAllVideos() {
  // console.log("running getall videos")
  return async function (dispatch) {
    return await axios.get(PROD_URL + "/allVideos").then(async (response) => {
      // console.log("resposne data", response.data.allVideos)
      var allVideoshere = response.data.allVideos;
      allVideoshere.reverse();
      // console.log("resposne data after reverse ",allVideoshere)
      dispatch({
        type: "ALL_Videos",
        allVideos: allVideoshere,
      });
      // console.log("after all videos to get selected transcripts")
      let allSelectedTranscripts = await axios.get(
        PROD_URL + "/getSelectedtranscripts"
      );
      // console.log("selected transcripts in allvideos",allSelectedTranscripts)
      if (allSelectedTranscripts.data.selectedTranscripts.length >= 0) {
        dispatch({
          type: "SET_HIGHLIGHT",
          highlights: allSelectedTranscripts.data.selectedTranscripts,
        });
        // setHighlight(allSelectedTranscripts.data.selectedTranscripts);
      }
    });
  };
}

export function setSideNav(setDisable, setShow) {
  return async function (dispatch) {
    dispatch({
      type: "Disable_sideNav",
      disable: setDisable,
      show: setShow,
    });
  };
}
export function setDataArray(dataArray) {
  // console.log("DATA IN ACTION",dataArray)
  return async function (dispatch) {
    dispatch({
      type: "DATA_ARRAY",
      dataArray: dataArray,
    });
  };
}

export function setVideosSet(videosChecked) {
  // console.log("DATA IN ACTION",videosChecked)
  return async function (dispatch) {
    dispatch({
      type: "VIDEOS_CHECKED",
      videosChecked: videosChecked,
    });
  };
}

export function setQueryWords(queryWords) {
  // console.log("DATA IN ACTION",videosChecked)
  return async function (dispatch) {
    dispatch({
      type: "SET_QUERY_WORDS",
      queryWords: queryWords,
    });
  };
}
export function setSelectedTranscript(selectedTranscript) {
  // console.log("DATA IN ACTION",selectedTranscript)
  return async function (dispatch) {
    dispatch({
      type: "SET_SELECTED_TRANSCRIPT",
      selectedTranscript: selectedTranscript,
    });
  };
}

export function setGroupCheck(groupChecked) {
  // console.log("DATA IN ACTION Group",groupChecked)

  return async function (dispatch) {
    dispatch({
      type: "GROUP_CHECKED",
      groupChecked: groupChecked,
    });
  };
}

export function setGroup(group, id) {
  // console.log("ID in action set group",id)

  // console.log("grouuuuuuuuuppppppp",group)
  return async function (dispatch) {
    return await axios
      .post(PROD_URL + "/setGroup", { group, id })
      .then(async (response) => {
        // cons.ole.log("resposne data in set group", response)
        // var allVideoshere=response.data.allVideos
        // allVideoshere.reverse()
        // // console.log("resposne data after reverse ",allVideoshere)
        // dispatch({
        // //   type: "ALL_Videos",
        // //   allVideos:allVideoshere
        // });
        if (response) {
          dispatch({
            type: "SEND",
            payload: "",
          });
        }
      })
      .catch((err) => {
        // console.log("ERROR", err);
      });
  };
}

export function setTrimmedVideos(trimmedVideosData) {
  return async function (dispatch) {
    dispatch({
      type: "SET_TRIMMED_VIDEOS",
      trimmedVideosData: trimmedVideosData,
    });
  };
}

export function setHighlight(highlights) {
  // console.log("setting highlight",highlights)
  return async function (dispatch) {
    dispatch({
      type: "SET_HIGHLIGHT",
      highlights: highlights,
    });
  };
}
