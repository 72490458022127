import { React, useState, useEffect } from "react";
// import "./popup.css"
import * as actionTypes from "../../Store/Action/action";
import { connect } from "react-redux";
import SideNav from "../sideNav/sideNav";
import TopBar from "../TopBar/topBar";
// import Axios from "axios";
import axios from "axios";
import { DEV_URL, PROD_URL } from "../../../api";
import "./TrimmedVideo.css";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));
const TrimmedVideos = (props) => {
  const classes = useStyles();
  const fetchAllTrimmedVideos = async () => {
    let allTrimmedVideos = await axios.get(PROD_URL + "/allTrimmedVideos");
    // console.log("got all videos", allTrimmedVideos);

    //creating an object for clips for each video. For
    //Each key-value pair in the object, key is for the video
    // and value is an array of clips created from that video
    let filterdata = {};

    allTrimmedVideos.data.trimmedVideos.map((trimmedVideo) => {
      if (filterdata[trimmedVideo.videoName] === undefined) {
        filterdata[trimmedVideo.videoName] = [trimmedVideo];
      } else {
        filterdata[trimmedVideo.videoName].push(trimmedVideo);
      }
    });

    // console.log("filterData", filterdata);

    props.setTrimmedVideos(filterdata);
  };

  useEffect(() => {
    // console.log("PROPS IN TRIMMED", props);
  }, [props.trimmedVideosData]);

  useEffect(() => {
    // console.log("trimmedVideos mounted");
    // console.log(props.trimmedVideosData);
    if (Object.keys(props.trimmedVideosData).length == 0) {
      // console.log("fetching");
      fetchAllTrimmedVideos();
      // console.log("ALLT TRIMMED VIDEOS",allTrimmedVideos)
    }
  }, []);

  const handleDeleteTrimmedVideo = async (e, data) => {
    // console.log("e", e);
    // console.log("data", data);
    let allTrimmedVideos = await axios.post(PROD_URL + "/removeTrimmedVideo", {
      data,
    });
    if (allTrimmedVideos.data.deleted == "success") {
      fetchAllTrimmedVideos();
    }
  };

  return (
    <div>
      <TopBar title={"Deep Aspect"}></TopBar>
      <div className="dashboardParent  col-md-12 " style={{ paddingRight: 0 }}>
        <div
          className="sidenav col-sm-2 "
          style={{
            paddingRight: 0,
          }}
        >
          <SideNav></SideNav>
        </div>
      </div>
      <h3 style={{ marginLeft: 450, marginTop: 20, marginBottom: 20 }}>
        Highlighed Transcript Snippets
      </h3>
      {(!props.trimmedVideosData ||
        Object.keys(props.trimmedVideosData).length <= 0) && (
        <div style={{ marginLeft: 100 }}>
          <h4 style={{ color: "red" }}>No selection found </h4>
        </div>
      )}
      {props.trimmedVideosData &&
        Object.keys(props.trimmedVideosData).length > 0 && (
          <div style={{ marginLeft: 100 }}>
            {Object.keys(props.trimmedVideosData).map((trimmedVideoData) => {
              return (
                <div
                  style={{ border: "1px solid black", margin: 10, padding: 10 }}
                >
                  <h3 className="trimmedVideotitle">{trimmedVideoData}</h3>
                  {props.trimmedVideosData[trimmedVideoData].map((data) => {
                    return (
                      <div className="trimmedVideodiv">
                        <video
                          style={{ width: 300, height: 200 }}
                          controls
                          src={`${data.videoLink}`}
                        ></video>
                        <div className="trimmedTranscript">
                          {data.wordsArray.map((word) => {
                            return <span>{word.word} </span>;
                          })}
                        </div>
                        <div style={{ width: "100px" }}>
                          <Button
                            onClick={(e) => {
                              handleDeleteTrimmedVideo(e, data);
                            }}
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    trimmedVideosData: state.reducer.trimmedVideosData,
  };
};
export default connect(mapStateToProps, actionTypes)(TrimmedVideos);
